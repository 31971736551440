<template>
  <v-list dense>
    <v-list-group
      class="aside-menu-list-group" 
      color="#FFF"
      v-for="(grupo, grupoKey) in route" :key="grupoKey">
       
      <template v-slot:activator>
        <v-list-item-title class="font-weight-bold"> {{ grupoKey }} </v-list-item-title>
      </template>

      <template v-for="(subgrupo, subgrupoKey) in grupo">
        <template v-if="subgrupo.length > 1">
          <v-list-group
            :key="subgrupoKey"
            sub-group
            color="#FFF">
            <v-icon slot="appendIcon" large size="24">mdi-chevron-down</v-icon>

            <template v-slot:activator>
              <v-list-item-title class="font-weight-bold"> {{ subgrupoKey }} </v-list-item-title>
            </template>
          
            <template v-for="item in subgrupo">
              <v-list-item ripple class="aside-menu-list-group--link" :key="item.path" :to="{ name: item.path }" v-if="item.path !== ''">
                <v-list-item-title> {{ item.realName }} </v-list-item-title>
              </v-list-item>
            </template>
          </v-list-group>
        </template>

        <template v-else>
          <template v-for="item in subgrupo">
            <v-list-item ripple class="aside-menu-list-group--link" :key="item.name" :to="{ name: item.name }" v-if="item.name !== ''">
              <v-list-item-title> {{ item.realName }} </v-list-item-title>
            </v-list-item>
          </template>
        </template>
      </template>
    </v-list-group>
  </v-list>
</template>

<script>
export default {
  name: 'BrMenuItem',
  props: {
    route: {
      type: Object
    }
  },
  computed: {
    isVisible: () => (this.route.meta && (this.route.meta.visible === undefined || this.route.meta.visible)) ? !!1 : !!0,
  },

  methods: {
    name (name) {
     return  String(name)
        .toLowerCase()
        .split("-")
        .map(s => s.charAt(0).toUpperCase() + s.slice(1))
        .join(" ")
    } 
  }
}
</script>