<template>
  <div class="btn-burger" :class="{ active }"></div>
</template>

<script>
export default {
  name: "MenuBurger",
  props: ['active']
}
</script>

<style lang="scss" scoped>
.btn-burger {
  border-top: 2px solid #FFF;
  height: 12px;
  position: absolute;
  cursor: pointer;

  &, &:before, &:after {
    transition: all 0.3s ease-in;
    width: 20px;
  }

  &:before, &:after {
    content: "";
    display: block;
    position: absolute;
    height: 2px;
    left: 0;
    background: #FFF;
  }

  &:before {
    top: 43%;
    transform: translateY(-50%);
  }
  &:after {
    bottom: 0;
  }

  &.active {
    border-color: transparent;
    &:before {
      transform: rotate(45deg);
      left: 0px;
      width: 21px;
    }

    &:after {
      transform: rotate(135deg);
      bottom: 4px;
    }
  }
}
</style>