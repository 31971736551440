<template>
  <v-navigation-drawer
      app
      clipped
      left
      v-model="drawer"
      :color="variables.colorPrimaryDarken"
      class="aside"
      dark
      :disable-resize-watcher="true"
      :width="230"
      :mobile-breakpoint="1400"
    >

      <v-container>
        <v-btn icon dark @click.stop="draw" class="icon-close-mobile">
          <buger :active="true" />
        </v-btn>
      </v-container>
      
      <div class="aside-menu-mobile">
        <v-list>
          <v-list-group class="aside-menu-list-group aside-menu-list-group--usuario" color="#FFF">
            <template v-slot:activator>
              <v-list-item class="aside-menu-list-group--usuario-item">
                <!-- <v-list-item-avatar class="aside-menu-list-group--usuario-avatar">
                  <v-img src="https://cdn.vuetifyjs.com/images/lists/1.jpg"></v-img>
                </v-list-item-avatar> -->
                
                <v-list-item-content>
                  <v-list-item-title class="aside-menu-list-group--usuario-titulo">{{ user.name }} </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>

            <v-list-item
              class="aside-menu-list-group--link"
              v-if="termoAtual"
              @click="$router.push('/termo-consentimento')"> 
                Contratos
            </v-list-item>

            <v-list-item
              class="aside-menu-list-group--link"
              @click="logout()"> 
                Sair
            </v-list-item>
          </v-list-group>
          <!-- <v-list-item>
            <v-list-item-title class="aside-menu-list-group--usuario-titulo">{{ user.name }} </v-list-item-title>
          </v-list-item> -->
        </v-list>

        <v-container>
          <v-divider />
        </v-container>

        <!-- <v-container>
          <v-autocomplete
            label="Buscar"
            placeholder="Buscar"
            :items="states"
            :filter="activeFilter"
            height="40px"
            flat
            single-line
            prepend-icon="search"
            class="aside-menu-busca"
          >
            <template>
              <v-icon>search</v-icon>
            </template>
          </v-autocomplete>
        </v-container> -->
      </div>

      <div :dense="true">
        <BrMenuItem :route="listaMenuOrdenado" />
        <!-- <template v-for="(val, k) in menuItens()">
        
        </template> -->
      </div>

      <template v-slot:append>
        <div class="pa-2">
          <v-btn block color="#FFF" @click="logout()">Sair</v-btn>
        </div>
      </template>
  </v-navigation-drawer>
</template>

<script>
import Events from '@/core/service/events'
import { mapActions, mapGetters} from 'vuex'
import storage from '@/core/service/localStorage'
import variables from '@/assets/styles/helpers/_variables.scss'
import BrMenuItem from './components/menu-item'
import buger from '@/views/components/burger'

export default {
  name: 'BrAside',
  components: {
    // BrMenuItem: () => import('./components/menu-item')
    BrMenuItem,
    buger
  },
  data: () => ({
    drawer: false,
    activeFilter: null,
    states: null,
  }),

  watch: {
    drawer: (val) => {
      Events.$emit('aside::alterDrawer', val)
    }
  },

  mounted () {
    Events.$on('appBar::drawer', () => {
      this.drawer = !this.drawer
    })

    this.getTermos()
  },

  methods: {
    ...mapActions('auth', ['getUser']),
    ...mapActions('termoConsentimento', ['getTermos']),
    logout () {
      storage.remove('auth-token')
      storage.remove('jwt')
      this.$router.replace('/auth')
    },

    draw () {
      this.drawer = !this.drawer
    },

    ordernar (val) {
      let ordered = {}

      Object.keys(val).sort().forEach(function(key) {
        ordered[key] = val[key];
      })
      // console.log(ordered)
      return ordered
    }
  },

  computed: {
    ...mapGetters('users', ['listaMenuTratado']),
    ...mapGetters('auth', ['user']),
    ...mapGetters('termoConsentimento', ['termoAtual']),
    variables: () => variables,
    routes () {
      return this.$router
    },

    listaMenuOrdenado () {
      return this.ordernar(this.listaMenuTratado)
    }
  }
}
</script>

<style lang="scss" src="@/assets/styles/view/aside.scss"></style>